<template>
  <a-modal width="600px" :visible="visible" :closable="false">
    <h5 class="mt-2">
      Approve Customer
    </h5>
    <a-divider class="my-2" />
    <a-spin :spinning="isLoading" size="large" tip="Sedang mengunggah file...">
      <div class="d-flex align-items-center">
        <a-tag color="red">
          Customer :
        </a-tag>
        <h6 class="m-0">
          {{ dataCustomer?.customer_name || '-' }}
        </h6>
      </div>
      <div class="mt-4">
        <label>Ext Code</label>
        <a-input
          v-model="form.extCode"
          class="input"
          placeholder="Masukan ext code"
        />
      </div>
      <div class="mt-4">
        <label>Customer Category</label>
        <a-select
          v-model="form.categoryExtCode"
          placeholder="Pilih customer category"
          class="w-100 select-antd-default filter"
          @focus="getCustomerCategories"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="item in listCustomerCategory" :key="item.id">
            {{ item.category_name }}
          </a-select-option>
        </a-select>
      </div>
    </a-spin>
    <template #footer>
      <a-button key="back" :disabled="isLoading" class="mr-2" @click="typeof back !== 'undefined' && back(), onClose()">
        Cancel
      </a-button>
      <a-button key="submit" :disabled="isLoading || !form.extCode || !form.categoryExtCode" type="primary" @click="onSubmitModal()">
        Approve Customer
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { getCustomerCategory } from '@/api/channels/distributor'
import getQueryParam from '@/utils/getQueryParam'

export default {
  name: 'ModalApproveCustomer',
  components: {
  },
  mixins: [getQueryParam],
  props: {
    dataCustomer: Object,
    visible: Boolean,
    back: Function,
    onClose: Function,
    isLoading: Boolean,
  },
  data() {
    return {
      jsonData: [],
      uploadedFile: [],
      form: {
        extCode: '',
        categoryExtCode: '',
      },
      listCustomerCategory: [],
      fetching: false,
    }
  },
  computed: {
  },
  watch: {
    'visible': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (!!value) {
          this.form.categoryExtCode = ''
          this.form.extCode = ''
          this.listCustomerCategory = []
        }
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
    },
    async getCustomerCategories() {
      this.fetching = true
      await getCustomerCategory({
        business_id: this.$route.query.business_id,
        params: {
          business_id: this.$route.query.business_id,
          page: 1,
          limit: 999,
          order: 'createdAt',
        },
      })
      .then(({ data }) => {
        this.listCustomerCategory = data?.content ?? []
        this.total = data?.totalElements ?? 0
      })
      .catch(err => {
        this.listCustomerCategory = []
        this.total = 0
        console.error(err)
      })
      .finally(() => this.fetching = false)
    },
    onSubmitModal() {
      const categoryExtCode = this.listCustomerCategory.find(category => category.id === this.form.categoryExtCode)
      this.$emit('handleSubmitModal', {
        params: {
          extCode: this.form.extCode,
          CategoryExtCode: categoryExtCode?.category_ext_code || '',
        },
        store_code: this.dataCustomer?.store_code,
      })
    },
  },
}
</script>

<style lang="scss" module>
</style>