<template>
  <a-modal width="600px" :visible="visible" :closable="false">
    <h5 class="mt-2">
      List Tagihan Customer
    </h5>
    <a-divider class="my-3" />
    <a-spin :spinning="isLoading" size="large" tip="Sedang mengunggah file...">
      <div class="form-row px-0 mt-1">
        <div class="col-2 text-muted">
          Nama
        </div>
        <div class="col-10 pbz-font body-sm-regular">
          : <span>
            {{ dataCustomer?.customer_name || '-' }}
          </span>
        </div>
        <div class="col-2 text-muted">
          Email
        </div>
        <div class="col-10 pbz-font body-sm-regular">
          : <span>
            {{ dataCustomer?.email || '-' }}
          </span>
        </div>
        <div class="col-2 text-muted">
          Kategori
        </div>
        <div class="col-10 pbz-font body-sm-regular">
          : <span>
            {{ dataCustomer?.category_name || '-' }}
          </span>
        </div>
      </div>
      <a-spin :spinning="fetching" tip="Mohon Tunggu . . .">
        <template v-if="isEmptyDistributor">
          <a-empty class="mt-4" description="Tidak ada data distributor" />
        </template>
        <template v-else>
          <div class="mt-4">
            <a-collapse default-active-key="0">
              <a-collapse-panel v-for="(item, index) in listDistributor" :key="index" :header="listDetailDistributor[item?.seller_id]?.name || '-'">
                <template v-if="listTagihan[item?.seller_id]?.length">
                  <div style="max-height: 400px; overflow: auto">
                    <div v-for="({ due, order_number, order, creditlimit_usage, overdue }, key) in listTagihan[item?.seller_id]" :key="key" class="d-flex flex-column">
                      <a-divider v-if="key !== 0" />
                      <div class="d-flex flex-column p-2">
                        <div class="d-flex justify-content-between">
                          <span class="h5">{{ order_number || order }}</span>
                          <div class="d-flex flex-column align-items-end">
                            <div style="color: #767676">
                              Batas waktu pelunasan:
                            </div>
                            <div v-if="due" class="d-flex">
                              <a-tag v-if="overdue" color="red">
                                Overdue
                              </a-tag>
                              <div class="text-dark ml-1">
                                {{ $moment(due, 'YYYY-MM-DD').format("DD MMM YYYY") }}
                              </div>
                            </div>
                            <div v-else>
                              <a-tag color="blue" class="mr-0 mt-2">
                                Order On Process
                              </a-tag>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div class="d-flex flex-column">
                            <span class="h6 my-1" style="color: #767676">Tagihan</span>
                            <span class="h6 my-1" style="color: var(--biz-secondary-100);">{{ Math.abs(creditlimit_usage) | currency }}</span>
                          </div>
                          <div class="d-flex align-items-end">
                            <!-- <div style="border: solid 1px var(--biz-primary-100); color: var(--biz-primary-100)"
                              class="btn d-flex align-items-center justify-content-center rounded my-2 w-100">
                              <span class="h6 mb-0" @click.stop="showDetail(order_id)">Lihat Detail</span>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <a-empty description="Tidak ada tagihan" />
                </template>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </template>
      </a-spin>
    </a-spin>
    <template #footer>
      <a-button key="back" :disabled="isLoading" class="mr-2" @click="typeof back !== 'undefined' && back(), onClose()">
        Tutup
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import {
  getCustomerCategory ,
  getCustomerDetail,
  getListBillCustomerBySellerId,
  getDetailDistributor,
} from '@/api/channels/distributor'
import getQueryParam from '@/utils/getQueryParam'

export default {
  name: 'ModalListTagihanCustomer',
  components: {
  },
  mixins: [getQueryParam],
  props: {
    dataCustomer: Object,
    visible: Boolean,
    back: Function,
    onClose: Function,
    isLoading: Boolean,
  },
  data() {
    return {
      jsonData: [],
      uploadedFile: [],
      form: {
        extCode: '',
        categoryExtCode: '',
      },
      listCustomerCategory: [],
      fetching: false,
      detailDataCustomer: null,
      listDistributor: [],
      listTagihan: null,
      listDetailDistributor: null,
      isEmptyDistributor: false,
    }
  },
  computed: {
  },
  watch: {
    'visible': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (!!value) {
          this.fetchDetailCustomer()
        }
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
    },
    fetchDetailCustomer() {
      this.fetching = true
      const { business_id } = this.$route.query
      getCustomerDetail({
        customer_id: this.dataCustomer.customer_id,
        business_id,
      })
        .then(({ data }) => {
          const { distributor } = data
          if (distributor?.length > 0) {
            const distinctDistributor = distributor.filter((item, index, self) => 
              index === self.findIndex((t) => t.seller_id === item.seller_id),
            )
            this.listDistributor = distinctDistributor
            const onlyId = distinctDistributor.map(item => item.seller_id)
            this.fetchListTagihanBySellerId(onlyId)
            this.fetchDetailDistributorBySellerId(onlyId)
          } else {
            this.isEmptyDistributor = true
            return this.fetching = false
          }
        })
    },
    fetchDetailDistributorBySellerId(arrSellerId) {
      const promises = []
      arrSellerId.forEach((sellerId) => {
        promises.push(
          getDetailDistributor(sellerId)
          .then(({ data: { data: response } }) => {
            let responseList = []
            if (response) {
              responseList = response
            }
            return {
              [sellerId]: responseList,
            }
          }),
        )
      })
      Promise.all(promises)
      .then((data) => {
        let newListDistributor = {}
        if (data?.length > 0) {
          data.forEach((item) => {
            newListDistributor = {
              ...newListDistributor,
              ...item,
            }
          })
        } else {
          newListDistributor = null
        }
        this.listDetailDistributor = newListDistributor
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.fetching = false
      })
    },
    fetchListTagihanBySellerId(arrSellerId) {
      const { id: channelId } = this.$route.params
      const promises = []
      arrSellerId.forEach((sellerId) => {
        promises.push(
          getListBillCustomerBySellerId({
            channel_id: channelId,
            seller_id: sellerId,
            buyer_id: this.dataCustomer.customer_id,
            params: {
              acknowledged: false,
            },
          })
          .then(({ data: { data: response } }) => {
            let responseList = []
            if (response?.list?.length > 0) {
              responseList = response.list
            }
            return {
              [sellerId]: responseList,
            }
          }),
        )
      })
      Promise.all(promises)
      .then((data) => {
        let newListTagihan = {}
        if (data?.length > 0) {
          data.forEach((item) => {
            newListTagihan = {
              ...newListTagihan,
              ...item,
            }
          })
        } else {
          newListTagihan = null
        }
        this.listTagihan = newListTagihan
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.fetching = false
      })
    },
    onSubmitModal() {
      const categoryExtCode = this.listCustomerCategory.find(category => category.id === this.form.categoryExtCode)
      this.$emit('handleSubmitModal', {
        params: {
          extCode: this.form.extCode,
          CategoryExtCode: categoryExtCode?.category_ext_code || '',
        },
        store_code: this.dataCustomer?.store_code,
      })
    },
  },
}
</script>

<style lang="scss" module>
</style>