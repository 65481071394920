<template>
  <div>
    <h1 class="h3">
      {{ $t('customer.list') }}
    </h1>
    <div class="mt-4 d-flex flex-wrap">
      <a-input-search
        v-model.trim="filterValue.q"
        size="large"
        class="w-auto col-md-6 px-0 mb-2 mr-3"
        :loading="loadingInputSearch"
        :placeholder="$t('customer.search')"
        :disabled="isLoading"
        allow-clear
        @search="onSearchInput"
      >
        <a-button slot="enterButton" type="primary" icon="search" :disabled="isLoading || loadingInputSearch">
          {{ $t('utils.search') }}
        </a-button>
      </a-input-search>

      <a-select
        v-model="filterValue.status"
        class="mb-2 mr-5 select-antd-default"
        style="width: 155px"
        size="large"
        :disabled="isLoading"
        @change="pagination.page = 1; onSearch()"
      >
        <a-select-option
          v-for="status in filterStatus"
          :key="status.label"
          :value="status.value"
        >
          {{ $t(`customer.${status.label}`) }}
        </a-select-option>
      </a-select>

      <div
        v-if="permission.includes('WRITE')"
        class="mb-2 flex-grow-1 text-right"
      >
        <a-button
          ghost
          type="primary"
          size="large"
          class="mr-3"
          :disabled="isLoading"
          @click="handleExport"
        >
          {{ $t('customer.export') }}
        </a-button>
        <a-button
          type="primary"
          size="large"
          :disabled="isLoading"
          @click="toInviteCustomer"
        >
          {{ $t('customer.invite') }}
        </a-button>
      </div>
    </div>

    <div class="mt-3">
      <a-table
        class="tb-white"
        size="small"
        :columns="columns"
        :row-key="record => record.customer_id"
        :data-source="customerList"
        :loading="isLoading"
        :pagination="false"
      >
        <template slot="state" slot-scope="text">
          {{ text ? $t(`customer.${text.toLowerCase()}`) : '' }}
        </template>
        <template slot="distributor" slot-scope="text">
          {{ printDistributorName(text) }}
        </template>
        
        <template slot="status_active" slot-scope="text">
          <div v-if="text.is_active" class="active-label">
            Active
          </div>
          <div v-else class="deactive-label">
            Inactive
          </div>
        </template>
        <template slot="action" slot-scope="record">
          <a-dropdown v-if="permission.includes('WRITE')">
            <a @click.prevent="">
              <a-icon type="more" />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  v-if="record?.state?.toUpperCase() === 'NEW'"
                  class="py-2"
                  @click="selectedDataCustomer = record, showModalApproveCustomer = true"
                >
                  <a-icon type="file-done" />
                  {{ $t('customer.approval') }}
                </a-menu-item>
                <a-menu-item
                  class="py-2"
                  @click="() => toEditCustomer(record.customer_id)"
                >
                  <a-icon type="edit" class="mr-2" />
                  {{ $t('customer.edit') }}
                </a-menu-item>
                <a-menu-item
                  v-if="record.is_active && !isDistributor"
                  class="py-2"
                  @click="onDeactive(record)"
                >
                  <a-icon type="delete" />
                  Non Aktifkan
                </a-menu-item>

                <a-menu-item
                  v-if="!record.is_active && !isDistributor"
                  class="py-2"
                  @click="onActive(record)"
                >
                  <a-icon type="edit" />
                  Aktifkan
                </a-menu-item>
                <a-menu-item
                  class="py-2"
                  @click="selectedDataCustomer = record, showModalListTagihan = true"
                >
                  <a-icon type="dollar" />
                  Check Tagihan Pelanggan
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
      <div class="mt-3 text-right">
        <a-pagination
          v-model="pagination.page"
          :page-size="pagination.size"
          :page-size-options="sizeOptions"
          :total="pagination.total"
          :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
          :disabled="isLoading"
          show-size-changer
          @change="onPageChange"
          @showSizeChange="onShowSizeChange"
        />
      </div>

      <ModalConfirm
        :visible="is_modal_status"
        ok-label="Ya"
        cancel-label="Batal"
        :title="is_active ? 'Apakah anda yakin akan menonaktifkan Pelanggan atas nama' : 'Apakah anda yakin akan mengaktifkan Pelanggan atas nama'"
        :main-title="customer_name"
        :icon="is_active ? 'close' : 'check'"
        @ok="handleStatusCustomer"
        @cancel="handleCancelModalStatus"
      />
      <ModalApproveCustomer
        :data-customer="selectedDataCustomer"
        :is-loading="loadingApproveCustomer"
        :visible="showModalApproveCustomer"
        :on-close="() => showModalApproveCustomer = false"
        @handleSubmitModal="onSubmitApproveCustomer"
      />
      <ModalListTagihanCustomer
        :data-customer="selectedDataCustomer"
        :visible="showModalListTagihan"
        :on-close="() => showModalListTagihan = false"
      />
    </div>
  </div>
</template>

<script>
import { getCustomers, crudCustomer, updateCustomerStatus, approveCustomer } from '@/api/channels/distributor'
import { columnsCustomer } from '@/data/columns'
import XLSX from 'xlsx';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue'
import ModalApproveCustomer from '@/views/customer-channel/ModalApproveCustomer.vue';
import ModalListTagihanCustomer from '@/views/customer-channel/ModalListTagihanCustomer.vue';

const formDataCustomer = () => ({
  email: '',
  // mobile: '',
  name: '',
  password: '',
  confirmpassword: '',
  category: '', // undefined
  line1: '',
  line2: '',
  country: "228", /** @NOTE : Define value to Indonesia */
  city: '', // []
  subdistrict: null,
  postcode: '',
  city_distributor: [],
  distributor: [],
  sales_code: [],
  payment_model: [],
  credit_limit: '',
  levelling_bond: '',
})

export default {
  name: 'CustomerList',
  components: {
    ModalConfirm,
    ModalApproveCustomer,
    ModalListTagihanCustomer,
  },
  data() {
    return {
      isLoading: false,
      customerList: [],
      sizeOptions: ['10', '20', '30', '40', '50'],
      params: {
        page: 1,
        limit: 10,
      },
      showModal: false,
      modalType: 'add',
      columns: columnsCustomer,
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      filterStatus: [
        { label: 'all', value: 'ALL' },
        { label: 'new', value: 'NEW' },
        { label: 'approve', value: 'APPROVE' },
      ],
      filterValue: {
        q: undefined, // ''
        status: 'ALL', // APPROVE, NEW
      },
      loadingInputSearch: false,
      loadingFormCustomer: false,
      formCustomer: formDataCustomer(),
      customer_id: '',
      customer_name: '',
      is_active: false,
      is_modal_status: false,
      loadingApproveCustomer: false,
      showModalApproveCustomer: false,
      selectedDataCustomer: null,
      showModalListTagihan: false,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    permission() {
      const key = this.$route.meta.key.split(':')[0].slice(0, -1)
      return this.$store.getters['user/can'](key, 'reverse')
    },
    userData() {
      return this.$store.state.user
    },
    isDistributor() {
      const roleName = (this.$store.state?.user?.restriction_base || '').toLowerCase()
      return roleName === 'warehouse' ? true : false
    },
  },
  watch: {
    // issue when reload page
    // permission(newValue) {
    //   if (!newValue.length) {
    //     this.$router.push({path: '/error/403', query: {...this.$route.query}})
    //   }
    // },
    '$route.query.warehouse_id'() {
      this.pagination.page = 1
      this.onSearch()
    },
    '$store.state.user.businessList'() {
      if (this.userData.restriction_base === 'Warehouse') {
        this.pagination.page = 1
        this.onSearch()
      }
    },
  },
  created() {
    this.pagination.page = 1
    this.onSearch()
  },
  methods: {
    printDistributorName(data) {
      const distinctData = [...new Set(data)]
      if (distinctData?.length > 0) {
        return distinctData.join(', ')
      }
      return '-'
    },
    async onSubmitApproveCustomer(data) {
      this.loadingApproveCustomer = true
      await approveCustomer({
        business_id: this.businessId,
        store_code: data.store_code,
        params: data.params,
      })
      .then(({ data }) => {
        this.$message.success(data?.message || 'Berhasil approve customer')
        this.pagination.page = 1
        this.onSearch()
      })
      .catch(() => {
        this.$message.error('Gagal approve, terjadi kesalahan.')
      })
      .finally(() => {
        this.showModalApproveCustomer = false
        this.loadingApproveCustomer = false
      })
    },
    onPageChange(pageNumber) {
      this.pagination.page = pageNumber
      this.onSearch()
    },
    onShowSizeChange(current, pageSize) {
      this.pagination.page = current
      this.pagination.size = pageSize
      this.onSearch()
    },
    onSearchInput() {
      this.loadingInputSearch = true
      this.pagination.page = 1
      this.onSearch()
    },
    async onSearch() {
      const { q, status } = this.filterValue
      let business_id = this.businessId
      if (!business_id) {
        business_id = this.$store.state.user?.businessList?.find(
          val => val.warehouse_id === this.$route.query?.warehouse_id,
        )?.business_id
      }
      if (!business_id) return

      this.isLoading = true
      await getCustomers({
        business_id,
        params: {
          ...this.pagination,
          q: q || undefined,
          status: status === 'ALL' ? undefined : status,
          warehouse_id: this.$route.query?.warehouse_id,
        },
      })
      .then(({ data }) => {
        this.customerList = data?.data || []
        this.pagination.total = data?.totalRows || 0
      })
      .catch(() => {
        this.customerList = []
        this.pagination.total = 0
      })
      .finally(() => {
        this.loadingInputSearch = false
        this.isLoading = false
      })
    },
    async handleExport() {
      const { q, status } = this.filterValue
      let business_id = this.businessId
      if (!business_id) {
        business_id = this.$store.state.user?.businessList?.find(
          val => val.warehouse_id === this.$route.query?.warehouse_id,
        )?.business_id
      }
      
      await getCustomers({
        business_id,
        params: {
          q: q || undefined,
          status: status === 'ALL' ? undefined : status,
          size: 1000,
          warehouse_id: this.$route.query?.warehouse_id,
        },
      })
      .then(({ data: response }) => {
        const data = response
        const flattenData = data?.data.map((obj) => ({
          customer_name: obj.customer_name,
          category_name: obj.category_name,
          email: obj.email,
          phone: obj.phone,
          distributor: obj.distributor,
          city: obj.city,
          state: obj.state,
        }))
        const rowData = [
          "Nama Pelanggan",
          "Kategori Pelanggan",
          "Email",
          "No. Handphone",
          "Distributor",
          "Kota",
          "Status",
        ]
        flattenData.sort((a, b) => {
          return this.$moment(b.paid_at) - this.$moment(a.paid_at);
        })
        const worksheet = XLSX.utils.json_to_sheet(flattenData);
        const workbook = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
        XLSX.writeFile(workbook,'customer_lists.xlsx')
      })
      .catch(() => {})
      .finally(() => { this.loadingInputSearch = false })
    },
    onDeactive(data) {
      this.is_active = true
      this.customer_id = data.customer_id
      this.customer_name = data.customer_name
      this.is_modal_status = true
    },
    onActive(data) {
      this.is_active = false
      this.customer_id = data.customer_id
      this.customer_name = data.customer_name
      this.is_modal_status = true
    },
    async handleStatusCustomer() {
      if (this.is_active) {
        await updateCustomerStatus({
          type: 'deactivate',
          customer_id: this.customer_id,
        })
        .then(() => {
          this.$message.success('Pelanggan berhasil di non aktifkan')
          this.onSearchInput()
          this.is_modal_status = false
        })
      } else {
        await updateCustomerStatus({
          type: 'activate',
          customer_id: this.customer_id,
        })
        .then(() => {
          this.$message.success('Pelanggan berhasil di aktifkan')
          this.onSearchInput()
          this.is_modal_status = false
        })
      }
    },
    handleCancelModalStatus() {
      this.is_active = false
      this.customer_id = ''
      this.is_modal_status = false
    },
    async onSubmit(value) {
      const channel_id = this.$route.params.id
      const { name, email, password, confirmpassword, code, category, line1, line2, country, city, subdistrict, postcode } = value
      const [ first_name, ...restName ] = name.split(' ')
      const address = {
        line1,
        line2,
        country_id: country, // 228,
        area_id: subdistrict, // 2551,
        city_id: city, // 1123,
        postcode, // 12810,
      }
      const userData = {
        first_name,
        last_name: restName.join(' '),
        email,
        phone: '',
      }
      const requestBody = {
        buyer: {
          channel_id,
          user: {
            ...userData,
            identity_number: '',
            address,
            birth_place: '', // "padang"
            birth_date: '', // "2000-08-04T13:11:00.761Z"
          },
          code, // 'BYR162'
          ext_code: '', // '10001'
          address,
          password,
          store: {
            name: "Toko",
            type: "STORE",
            // url: [
            //   { url: "http://tokopedia.com/" }, // http://tokopedia.com/abc
            // ],
            url: null,
            npwp: "",
          },
          image_data: [],
          customer_category_id: category, // "0ad5a625-506b-4232-9d76-a319530f0d67"
          // @NOTE : Dari Pilih Distributor
          sellers_id: [
            {
              // @NOTE : warehouse_id Dari mana datanya?
              warehouse_id: "ef54d345-95f9-483d-a5c2-ccee0a4ab5af", // NOTE : multiple
              seller_id: "cc5dd213-0a4f-4fc8-bc4e-84614cdbbaae",
              // @NOTE : sales_code / Kode Sales apa harus sama jumlah nya dgn 
              sales_code: 101,
              // @NOTE : Kalo distributor
              payment: {
                term_of_payment: "CBD", // required
                coin_flag: false, // required | NOTE : init to true
                credit_limit: 2900000,
                multiple_bond_allowed: "1",
                currency: "IDR",
                term_unit: "Month(s)",
                term_value: "9",
              },
            },
          ],
        },
        user: {
          ...userData,
          channel_id,
          password,
          confirmpassword,
          store_channel_id: null,
          change_login_req: false,
          user_name: email,
        },
      }
      // if (value) return

      this.loadingFormCustomer = true
      await crudCustomer({
        business_id: this.businessId,
        customer_id: undefined,
        data: { ...requestBody },
      })
      .then((res) => {
        const status = res?.data?.status
        if (status && status.toLowerCase() === 'ok') {
          this.showModal = false
          this.pagination.page = 1
          this.onSearch()
          this.$message.success('Pelanggan berhasil disimpan')
        } else {
          this.$message.error('Gagal simpan pelanggan')
        }
      })
      .catch(() => {})
      .finally(() => { this.loadingFormCustomer = false })
    },
    onShowModal(type, data) {
      this.modalType = type
      this.formCustomer = data ? {
        email: data.email,
        // mobile: data.phone,
        name: data.customer_name,
        category: data.category_name,
        country: "228",
        city: data.city, // data.city || [],
        code: data.store_code,
        city_distributor: [],
        distributor: data.distributor,
      } : formDataCustomer()

      this.showModal = true
    },
    onCloseModal() {
      this.showModal = false
      // this.formCustomer = formDataCustomer()
    },
    toInviteCustomer() {
      this.$router.push({
        name: `${this.$route.name}.form`,
        query: {
          warehouse_id: this.$route.query.warehouse_id,
          workspace_id: this.$route.query.workspace_id,
          business_id: this.businessId,
          sales_channel_id: this.$route.query.sales_channel_id,
          channel_code: this.$route.query.channel_code,
        },
      })
    },
    toEditCustomer(record) {
      this.$router.push({
        name: `${this.$route.name}.form`,
        query: {
          warehouse_id: this.$route.query.warehouse_id,
          workspace_id: this.$route.query.workspace_id,
          business_id: this.businessId,
          sales_channel_id: this.$route.query.sales_channel_id,
          channel_code: this.$route.query.channel_code,
          customer_id: record,
        },
      })
    },
  },
}
</script>

<style lang="scss">
.tb-white .ant-table-row {
  background-color: #fff !important;
}
// .ant-popover-arrow {
//   display: none !important;
// }
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
.active-label {
  color: green;
  font-weight: 800;
  text-align: center;
}
.deactive-label {
  color: red;
  font-weight: 800;
  text-align: center;
}
</style>
